/* BlueGradient.css */
/* .gradient-container {
    background: "linear-gradient(to bottom, rgba(0, 140, 255, 0.15), rgba(0, 0, 90, 0))";
    opacity: 0;
    transition: opacity 4s;
  }
  
  .gradient-container.show {
    opacity: 1;
    
  } */

  .gradient-container {
    height: 50%; /* Set the height to cover half of the window */
    width: 100%;
    background: linear-gradient(to bottom, rgba(0, 140, 255, 0.5), rgba(0, 0, 90, 0)); /* Blue gradient with transparency */
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0; /* Initially set to be transparent */
    transition: opacity 0.8s; /* Add transition to create a smooth fade-in effect */
    z-index: -1;
  }
  
  .gradient-container.show {
    opacity: 1;
  }